import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"

const Error = () => {
  return (
    <Link to="/">
      <Helmet title="Error"></Helmet>
      <Helmet>
        <link
          href={withPrefix(
            "https://cdn.jsdelivr.net/gh/xbaha/CSSLib@master/404err.css"
          )}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div>
        <div class="husky">
          <div class="mane">
            <div class="coat"></div>
          </div>
          <div class="body">
            <div class="head">
              <div class="ear"></div>
              <div class="ear"></div>
              <div class="face">
                <div class="eye"></div>
                <div class="eye"></div>
                <div class="nose"></div>
                <div class="mouth">
                  <div class="lips"></div>
                  <div class="tongue"></div>
                </div>
              </div>
            </div>
            <div class="torso"></div>
          </div>
          <div class="legs">
            <div class="front-legs">
              <div class="leg"></div>
              <div class="leg"></div>
            </div>
            <div class="hind-leg"></div>
          </div>
          <div class="tail">
            <div class="tail">
              <div class="tail">
                <div class="tail">
                  <div class="tail">
                    <div class="tail">
                      <div class="tail"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Error
